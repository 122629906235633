




























import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import ExpansionBarWithForm from "./ExpansionBarWithForm.vue";
import XForm from "../SimpleForm.vue";
import { emailReplacementForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";

const { values, attrs, validations, errors } = emailReplacementForm;

@Component({
  components: {XNotice, ExpansionBarWithForm },
  mixins: [validationMixin],
  computed: errors,
  validations,
})
class CardRowWithEmailForm extends Mixins(AppApiMixin, XForm) {
  @Prop({ default: false }) readonly showRow!: boolean;
  data = null;
  showMessage = false;
  values = values;
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;

  mounted() {
    this.data = null;
    this.showMessage = false;
  }

  @Watch("showRow")
  public showChanged() {
    if (!this.showRow) {
      this.resetValues();
    }
  }

  public submitForm() {
    this.useSubmitButtonLoading = true;

    const { changeEmail, getValues, resetValues } = this;
    const { email } = getValues();

    changeEmail(email)
      .then((_) => {
        const evtName = "show-dialog";

        if (_.data != null) {
          this.data = _.data;
          this.showMessage = true;

          throw new Error(this.data);
        } else {
          this.$emit(evtName, {name: evtName, email});
          resetValues();
        }
      })
      .catch(() => {
        console.error();
        this.showMessage = true;
    })
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }
}

export default CardRowWithEmailForm;
