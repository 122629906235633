import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AccountingPoint,
  AccountingPointsByObject,
  AccountingPointsPUState,
  ParamsByAccountingPoint
} from "@/models/accounting-point";
import {store} from "@/store/store";
import moment from "moment/moment";
import {fi} from "date-fns/locale";

@Module({ namespaced: true })
class AccountingPointsModule extends VuexModule {
  list: AccountingPoint[] | null = null;
  historyList: AccountingPoint[] | null = null;
  useHistoryList: boolean = false
  useMeteringPointSorting: boolean = false
  accountingPointsPU: AccountingPointsPUState | null = null;
  params: ParamsByAccountingPoint[] = [];

  public get allPoints() {
    return this.list
  }

  public get valuesInputDisabledForCounters() {
    let counterSendingPeriod = this.counterSendingPeriod;

    var valuesInputDisabled = {};
    Object.keys(counterSendingPeriod || {}).forEach((key) => {
      const periodDate = counterSendingPeriod[key]

      const currentDate = moment("00:00:00", "HH:mm:ss")
      const firstDate = moment(periodDate[0], 'DD.MM.YY', true)
      const secondDate = moment(periodDate[1], 'DD.MM.YY', true)

      if (firstDate > secondDate) {
        valuesInputDisabled[key] = !(currentDate >= firstDate || currentDate <= secondDate);
      } else {
        valuesInputDisabled[key] = !(currentDate >= firstDate && currentDate <= secondDate);
      }
      return
    })
    return valuesInputDisabled;
  }

  public get counterSendingPeriod(){
    const typeContract = store.state.contract.contract["типдоговора"]
    const constants = store.state.appEnviroment.constants

    let values = {};
    Object.values(this.accountingPointsPU || {}).forEach((item, index) => {
      let dates: string[] | null

      try{
        // проверка на применение констант ЮЛ_ИКУ_САЙТПОКАЗАНИЯОТ, ЮЛ_ИКУ_САЙТПОКАЗАНИЯДО
        if (typeContract.match(/^(Договор\sИКУ)/) !== null) {
          dates = getDatesFromConstants(constants, "ЮЛ_ИКУ_САЙТПОКАЗАНИЯОТ", "ЮЛ_ИКУ_САЙТПОКАЗАНИЯДО")
        }
        // проверка на применение других констант
        else {
          const counterParams = this.params[item.счетчикид]
          const paramsTypeOfRoom = Object.values(counterParams || {})
              .filter((param) => param.название == 'ВИДПОМЕЩ' && param.знач2 == '1')
          const paramsCategory = Object.values(counterParams || {})
              .filter((param) => param.название == 'ЦКАТЕГОРИЯ')[0]?.значение.trim() || ""

          // проверка на применение констант ЮЛ_ПРОЧ_НЕЖ_САЙТПОКОТ, ЮЛ_ПРОЧ_НЕЖ_САЙТПОКДО
          if (paramsTypeOfRoom.length > 0) {
            dates = getDatesFromConstants(constants, 'ЮЛ_ПРОЧ_НЕЖ_САЙТПОКОТ', 'ЮЛ_ПРОЧ_НЕЖ_САЙТПОКДО')
          }
          // проверка на применение констант ЮЛ_ПРОЧ_САЙТПОКАЗАНИЯОТ, ЮЛ_ПРОЧ_САЙТПОКАЗАНИЯДО
          else if (!paramsCategory || paramsCategory == "") {
            dates = getDatesFromConstants(constants, 'ЮЛ_ПРОЧ_САЙТПОКАЗАНИЯОТ', 'ЮЛ_ПРОЧ_САЙТПОКАЗАНИЯДО')
          }
          // проверка на применение констант ЮЛ_ПРОЧ_12ЦК_САЙТПОКОТ, ЮЛ_ПРОЧ_12ЦК_САЙТПОКДО
          else if (paramsCategory != "Третья" && paramsCategory != "Четвертая" && paramsCategory != "Пятая" && paramsCategory != "Шестая") {
            dates = getDatesFromConstants(constants, 'ЮЛ_ПРОЧ_12ЦК_САЙТПОКОТ', 'ЮЛ_ПРОЧ_12ЦК_САЙТПОКДО')
          }
          // проверка на применение констант ЮЛ_ПРОЧ_34ЦК_САЙТПОКОТ, ЮЛ_ПРОЧ_34ЦК_САЙТПОКДО
          else if (paramsCategory == "Третья" || paramsCategory == "Четвертая" || paramsCategory == "Пятая" && paramsCategory == "Шестая") {
            dates = getDatesFromConstants(constants, 'ЮЛ_ПРОЧ_34ЦК_САЙТПОКОТ', 'ЮЛ_ПРОЧ_34ЦК_САЙТПОКДО')
          }
        }
      }catch (e) {
        console.log(e)
      }
      values[item.счетчикид] = dates;
    })

    return values
  }

  public get sortedPointsByObject(): AccountingPointsByObject[] | null {
    const simpleObj: { [x: string]: any } = {};

    const list = this.useHistoryList ? this.historyList : this.list
    const keys = this.useMeteringPointSorting ? ['номерту', 'названиету'] : ['объект', 'названиеобъекта']

    if (list) {
      const points = list.reduce((acc, point) => {
        const objectId = point[keys[0]];
        const objectName = point[keys[1]];

        if (objectId in acc) {
          acc[objectId].pointList.push(point);
        } else {
          acc[objectId] = {
            objectId,
            objectName,
            pointList: [point]
          };
        }

        return acc;
      }, simpleObj);

      return Object.values(points).sort(
        (point1, point2) => point1.objectId - point2.objectId
      );
    }

    return null;
  }

  @Mutation
  setUseHistoryList(use: boolean) {
    this.useHistoryList = use
  }

  @Mutation
  setUseMeteringPointSorting(use: boolean) {
    this.useMeteringPointSorting = use
  }

  @Mutation
  setPoints(accountingPoints: AccountingPoint[] | null) {
    this.list = accountingPoints;
  }

  @Mutation
  setHistoryPoints(accountingPoints: AccountingPoint[] | null) {
    this.historyList = accountingPoints;
  }

  @Mutation
  setPointsPU(accountingPoints: AccountingPointsPUState) {
    this.accountingPointsPU = accountingPoints;
  }

  @Mutation
  setPointsParams(params: ParamsByAccountingPoint[]) {
    this.params = params;
  }
}

function dateFormat(date: string): string{
  return moment(date).format('DD.MM.YY')
}

function getDatesFromConstants(constants: any, firstConstant, secondConstant): string[]{
  return constants && [firstConstant, secondConstant].map(
      constantKey => dateFormat(constants[constantKey])
  );
}

export default AccountingPointsModule;
