function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',_vm._g({ref:"simpleForm",staticClass:"form"},_vm.$listeners),[_c('span',{staticClass:"form__legend"},[_vm._v(_vm._s(_vm.legend))]),_vm._t("afterLegend"),_c('div',{staticClass:"field_wrap"},[_vm._l((_vm.textFields),function(ref,fieldName){
var label = ref.label;
var rest = objectWithoutProperties( ref, ["label"] );
var attrs = rest;
return [_c('span',{key:label,staticClass:"form__label v-label",class:fieldName},[_vm._v(_vm._s(label))]),_vm._t("textField",null,{"attrs":attrs,"fieldName":fieldName})]})],2),_vm._t("beforeSubmitButton"),_c('div',{staticClass:"form__submit-box"},[_c('span',{class:[
        'form__label',
        'v-label',
        'text-center',
        _vm.submitErrorText && 'error--text',
        _vm.submitSuccessText &&
          'font-weight-regular success--text text--lighten-1 text-h6' ]},[_vm._v(" "+_vm._s(_vm.submitErrorText || _vm.submitSuccessText)+" ")]),_c('simple-button',{attrs:{"type":"submit","outlined":_vm.submitButtonOutlined,"disabled":_vm.submitButtonState,"loading":_vm.submitButtonLoading,"width":_vm.submitButtonWidth}},[_vm._v(" "+_vm._s(_vm.submitButtonText)+" ")])],1),_vm._t("afterSubmitButton")],2)}
var staticRenderFns = []

export { render, staticRenderFns }